import React, { useState, useEffect } from "react";
import './BottomSheet.css';

const tele = window.Telegram.WebApp;

const BottomSheet = ({ visible, onClose, product, token, onCartUpdate }) => {
  const [quantity, setQuantity] = useState(product?.quantity?.toString() || "0");
  const [initialQuantity, setInitialQuantity] = useState(product?.quantity || 0);
  const [applicableDeal, setApplicableDeal] = useState(null);

  useEffect(() => {
    setQuantity(product?.quantity?.toString() || "0");
    setInitialQuantity(product?.quantity || 0);
  }, [product]);

  useEffect(() => {
    if (product && product.bulk_deals && product.bulk_deals.length > 0) {
      const deal = product.bulk_deals
        .slice()
        .sort((a, b) => b.quantity - a.quantity) // Sort by quantity in descending order
        .find(deal => parseInt(quantity, 10) >= deal.quantity);
      setApplicableDeal(deal || null);
    } else {
      setApplicableDeal(null);
    }
  }, [quantity, product]);

  if (!visible || !product) return null;

  const handleQuantityChange = (e) => {
    const newQuantity = e.target.value;
    setQuantity(newQuantity);
  };

  const handleQuantityBlur = () => {
    const newQuantity = parseInt(quantity, 10) || 0;
    const minBulkDealQuantity = product.bulk_deals ? Math.min(...product.bulk_deals.map(deal => deal.quantity)) : 1;
    if (newQuantity < minBulkDealQuantity) {
      setQuantity(String("0"));
    } else {
      setQuantity(String(newQuantity));
    }
  };

  const handleDoneClick = async () => {
    // tele.showAlert(`th url is ${process.env.REACT_APP_API_URL}/carts/${product.id}`)
    const newQuantity = parseInt(quantity, 10);
    const minBulkDealQuantity = product.bulk_deals ? Math.min(...product.bulk_deals.map(deal => deal.quantity)) : 1;
    if (newQuantity !== initialQuantity && initialQuantity > 0) {
      const difference = newQuantity - initialQuantity;
      const url = `${process.env.REACT_APP_API_URL}/carts/${product.id}`;
      // tele.showAlert(`th url is ${url}`)
      try {
        const requestBody = {
          product_id: product.id,
          quantity: difference
        };
        const response = await fetch(url, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(requestBody)
        });
        const result = await response.json();
        if (result.ok) {
          onCartUpdate();
        } else {
          // Handle error
        }
      } catch (error) {
        // Handle error
      }
    } else if (initialQuantity === 0) {
      const url = `${process.env.REACT_APP_API_URL}/carts`;
      try {
        const requestBody = {
          product_id: product.id,
          quantity: Math.max(newQuantity, minBulkDealQuantity)
        };
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(requestBody)
        });
        const result = await response.json();
        if (result.ok) {
          onCartUpdate();
        } else {
          // Handle error
        }
      } catch (error) {
        // Handle error
      }
    }
  };

  return (
    <div className="bottom-sheet-overlay" onClick={onClose}>
      <div className="bottom-sheet" onClick={(e) => e.stopPropagation()}>
        <div className="bottom-sheet-header">
          {parseInt(quantity, 10) > 0 && applicableDeal ? (
            <h3>
              Qty {quantity} x {applicableDeal.bulk_price} Birr = {applicableDeal.bulk_price * parseInt(quantity, 10)} Birr
            </h3>
          ) : (
            <h2>Select Deal</h2>
          )}
          <span className="close-btn" onClick={onClose}>&times;</span>
        </div>
        <div className="bottom-sheet-content">
          <div className="quantity-controls">
            <button
              className="quantity-btn"
              onClick={() => {
                const newQuantity = Math.max(0, parseInt(quantity, 10) - 1);
                const minBulkDealQuantity = product.bulk_deals ? Math.min(...product.bulk_deals.map(deal => deal.quantity)) : 1;
                // window.Telegram.WebApp.showAlert(initialQuantity);
                if (newQuantity < minBulkDealQuantity) {
                  setQuantity("0");
                } else {
                  setQuantity(String(newQuantity));
                }
              }}
            >
              -
            </button>
            <input
              type="number"
              className="quantity-input"
              value={quantity}
              onChange={handleQuantityChange}
              onBlur={handleQuantityBlur}
            />
            <button
              className="quantity-btn"
              onClick={() => {
                const currentQuantity = parseInt(quantity, 10);
                const minBulkDealQuantity = product.bulk_deals ? Math.min(...product.bulk_deals.map(deal => deal.quantity)) : 1;
                if (currentQuantity === 0) {
                  setQuantity(String(minBulkDealQuantity));
                } else {
                  setQuantity(String(currentQuantity + 1));
                }
              }}
            >
              +
            </button>
          </div>

          {product && product.bulk_deals && product.bulk_deals.length > 0 && (
            product.bulk_deals
              .slice() // Create a shallow copy to avoid mutating the original array
              .sort((a, b) => a.quantity - b.quantity) // Sort by quantity
              .map((deal) => (
                <button
                  key={deal.id}
                  className="option-btn bulk-deal-btn"
                  onClick={() => setQuantity(String(Math.max(0, parseInt(deal.quantity, 10))))}
                >
                  Get {deal.quantity} {`(` + product.measuring_unit + `)`} For {deal.bulk_price} Birr Each
                </button>
              ))
          )}
        </div>
        <button className="done-btn" onClick={handleDoneClick}>Done</button>
      </div>
    </div>
  );
};

export default BottomSheet;