import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PaymentMethods.css';
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify';

const tele = window.Telegram.WebApp;

const PaymentMethods = ({ token, onClose, setSelectedMethod, activePopup, handlePay }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethod, setSelectedMethodState] = useState(null);
  const [deliveryCharge, setDeliveryCharge] = useState(0); // Default value
  const [payButtonClicked, setPayButtonClicked] = useState(false);
  const [accountNumber, setAccountNumber] = useState(''); // State to hold the input value

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL_V2}/orders/payment_methods`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data.ok) {
          setPaymentMethods(response.data.data);
        } else {
          tele.showAlert('Failed to fetch payment methods:', response.data);
        }
      } catch (error) {
        // tele.showAlert('Error fetching payment methods:', error);
        toast.error(
            `Error fetching payment methods :  ${ error.response && error.response.data && error.response.data.error.message}`
          );
      }
    };

    fetchPaymentMethods();
  }, [token]);

  useEffect(() => {
    const fetchDeliveryCharge = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/system/config`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${process.env.REACT_APP_BEARER_TOKEN}`
          }
        });

        if (response.data.ok) {
          setDeliveryCharge(response.data.data.last_mile_delivery_price);
        } else {
          // console.error('Failed to fetch delivery charge:', response.data);
          toast.error(
            `Failed to fetch delivery charge`
          );
        }
      } catch (error) {
        // console.error('Error fetching delivery charge:', error);
        toast.error(
            `Error fetching delivery charge :  ${ error.response && error.response.data && error.response.data.error.message}`
          );
      }
    };

    fetchDeliveryCharge();
  }, [token]);

  const handleSelectMethod = (method) => {
    const updatedMethod = {
      ...method,
      accountNumber: method.require_account_number ? '' : undefined, // Add accountNumber if required
    };
    setSelectedMethodState(updatedMethod);
    setSelectedMethod(updatedMethod);
    setAccountNumber(''); // Reset account number when a new method is selected
  };

  const handleAccountNumberChange = (e) => {
    const input = e.target.value;
    setAccountNumber(input);
  
    // Update selectedMethod with the accountNumber
    setSelectedMethod(prevMethod => ({
      ...prevMethod,
      accountNumber: input
    }));
  };

  useEffect(() => {
    if (selectedMethod) {
      if (activePopup === "paymentMethods") {
        tele.MainButton.show();
      }
    }
  }, [selectedMethod, activePopup, handlePay]);

  useEffect(() => {
    if (payButtonClicked) {
      handlePay(selectedMethod); // Now selectedMethod includes accountNumber if required
    }
  }, [selectedMethod, payButtonClicked]);

  tele.MainButton.onClick(() => setPayButtonClicked(true));

  return (
    <div className="payment-popup">
      <div className='header'>
        <h2>Payment</h2>
        <div onClick={onClose}>
          <IoClose className='close-icon' size={20} />
        </div>
      </div>
      <ul>
        {paymentMethods.map((method) => 
        method.label !== 'ChipChip Wallet' && 
        (
          <React.Fragment key={method.id}>
            <li
              className={selectedMethod && selectedMethod.id === method.id ? 'selected' : ''}
              onClick={() => handleSelectMethod(method)}
            >
              <input
                type="radio"
                checked={selectedMethod && selectedMethod.id === method.id}
                onChange={() => handleSelectMethod(method)}
              />
              <img src={method.logo} alt={method.label} />
              <span>{method.label}</span>
            </li>
  
            {/* Conditionally render the input field under the selected payment method */}
            {selectedMethod && selectedMethod.id === method.id && method.require_account_number && (
              <li className="account-number-input-container">
                <div className="phone-input-wrapper">
                  <span className="country-code">+251-7</span>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    value={accountNumber}
                    onChange={handleAccountNumberChange} // Use the new handler
                    className="account-number-input"
                    minLength={8}   // Set the minimum length to 8
                    maxLength={8}   // Set the maximum length to 8
                    required        // Ensures the field is not left empty (optional)
                  />
                </div>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
      <div className="footer">
        <h4>Delivery Charge {deliveryCharge} ETB</h4>
      </div>
    </div>
  );
};

export default PaymentMethods;
