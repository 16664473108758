// src/utils/loadGoogleMapsApi.js

export function loadGoogleMapsApi(apiKey) {
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => resolve(window.google);
        script.onerror = (error) => reject(error);
        document.head.appendChild(script);
      }
    });
  }
  