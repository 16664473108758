import React from "react";
import "./Card.css";
import Button from "../Button/Button";

function Card({ food, onAdd, onRemove }) {
  const { title, Image, price, quantity,measuring_unit } = food;

  const handleIncrement = () => {
    onAdd(food);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      onRemove(food);
    }
  };

  return (
    <div className="card">
      <span className={`${quantity !== 0 ? "card__badge" : "card__badge--hidden"}`}>
        {quantity}
      </span>
      <div className="image__container">
        <img src={Image} alt={title} />
      </div>
      <div className="card__divider"></div>
      <div className="card__title">
        <span className="card__name"><b>{title}</b></span>
        {/* <br /> */}
        <span className="card__price">{price}</span>
        {/* <br /> */}
        <span className="card__price">Price / {measuring_unit}</span>
      </div>
      <div className="btn-container">
        {quantity !== 0 ? (
          <>
            <Button title="-" type="remove" onClick={handleDecrement} />
            <Button title="+" type="add" onClick={handleIncrement} />
          </>
        ) : (
          <Button title="Buy" type="buy" onClick={handleIncrement} />
        )}
      </div>
    </div>
  );
}

export default Card;
