import React, { useState, useRef, useEffect, useCallback } from "react";
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './LocationSelector.css';
import userIcon from '../../assets/person.png';
import { loadGoogleMapsApi } from '../../utility/loadGoogleMapsApi';
import { IoClose } from "react-icons/io5";
import { App as KonstaApp, Page, Navbar } from "konsta/react";

const tele = window.Telegram.WebApp;

const LocationSelector = ({ onClose, toggleMapAndAutocomplete, showMapAndAutocomplete, existingLocations, locations, phoneNumber, setLocation }) => {
  const [position, setPosition] = useState([9.030, 38.74]);
  const [mapVisible, setMapVisible] = useState(true);
  const [localPhoneNumber, setLocalPhoneNumber] = useState(phoneNumber); // Initialize with prop value
  const searchInputRef = useRef(null);
  const phoneInputRef = useRef(null); // Reference to phone number input
  // Determine whether to show new location selection by default
  const [isNewLocation, setIsNewLocation] = useState(existingLocations.length === 0);
    const [theme, setTheme] = useState({});

    const applyTelegramTheme = useCallback(() => {
    const themeParams = tele.themeParams;
    const cssVars = {
      "--background-color": themeParams.bg_color || "rgb(19, 19, 52)",
      "--text-color": themeParams.text_color || "#fff",
      "--button-color": themeParams.button_color || "rgb(38, 121, 38)",
      "--button-text-color": themeParams.button_text_color || "#fff",
      "--hint-color": themeParams.hint_color || "#888",
      "--button-hover-color": themeParams.button_hover_color || "#0056b3",
    };
    setTheme(cssVars);
  }, []);

  const customIcon = L.icon({
    iconUrl: userIcon,
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38]
  });

  useEffect(() => {
    if (locations.length > 0) {
      setPosition([locations[0].location.latitude, locations[0].location.longitude]);
      setLocation({ new: false, id: locations[0].id });
    }
    applyTelegramTheme();
  }, [locations, setLocation]);

  useEffect(() => {
    const initializeAutocomplete = async () => {
      try {
        const google = await loadGoogleMapsApi(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

        if (isNewLocation) {
          if (navigator.geolocation) {
           
            navigator.geolocation.getCurrentPosition(
              async (pos) => {
                const { latitude, longitude } = pos.coords;
                setPosition([latitude, longitude]);
                const title = await reverseGeocode(latitude, longitude);
                setLocation({ new: true, position: [latitude, longitude], title: title });
              },
              (error) => {
                console.error("Error getting user location: ", error);
              }
            );
          } else {
            window.Telegram.webApp.showAlert("Geolocation is not supported by this browser.");
          }

          if (searchInputRef.current) {
            const cityBounds = new google.maps.LatLngBounds(
              new google.maps.LatLng(9.024666568, 38.737330384),
            );

            const options = {
              bounds: cityBounds,
              types: ['establishment'],
              componentRestrictions: { country: 'et' }
            };

            const autocomplete = new google.maps.places.Autocomplete(searchInputRef.current, options);

            searchInputRef.current.addEventListener('focus', () => {
              setMapVisible(false);
            });

            autocomplete.addListener('place_changed', () => {
              const place = autocomplete.getPlace();
              if (place.geometry) {
                const { lat, lng } = place.geometry.location;
                setPosition([lat(), lng()]);
                setMapVisible(true);
                setLocation({ new: true, position: [lat(), lng()], title: place.name });
              }
            });
          }
        }
      } catch (error) {
        console.error("Error loading Google Maps API: ", error);
      }
    };

    initializeAutocomplete();
  }, [isNewLocation, setLocation]);

  const LocationMarker = ({ position }) => {
    const map = useMap();
    useEffect(() => {
      map.setView(position);
    }, [position, map]);

    useMapEvents({
      async click(e) {  // Mark the click handler as async
        if (isNewLocation) {
          setPosition([e.latlng.lat, e.latlng.lng]);
          const title = await reverseGeocode(e.latlng.lng, e.latlng.lat); // Await the reverseGeocode function
          setLocation({ new: true, position: [e.latlng.lat, e.latlng.lng], title: title });
        }
      },
    });

    return position === null ? null : (
      <Marker position={position} icon={customIcon}></Marker>
    );
  };

  const handleLocationChange = (event) => {
    const selectedLocation = locations[event.target.value];
    if (selectedLocation && selectedLocation.location) {
      setPosition([selectedLocation.location.latitude, selectedLocation.location.longitude]);
      setLocation({ new: false, id: selectedLocation.id });
    }
  };

  const handlePhoneNumberChange = (event) => {
    const newPhoneNumber = event.target.value;
    setLocalPhoneNumber(newPhoneNumber);
    setLocation(prevState => ({
      ...prevState,
      phoneNumber: newPhoneNumber
    }));
    setMapVisible(false);
  };

  const handlePhoneInputBlur = () => {
    setMapVisible(true);
  };

  const reverseGeocode = async (lat, lon) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=18&addressdetails=1`);
      const data = await response.json();
      return data.display_name || "Unknown location";
    } catch (error) {
      console.error("Error with reverse geocoding: ", error);
      return "Unknown location";
    }
  };

  return (
    <KonstaApp theme="auto">
      <Page style={theme}>
    <div className="location-selector-popup">
      <div className="header">
        <h3>Set Delivery Info</h3>
        <div className="button-group">
          <button 
            onClick={() => setIsNewLocation(!isNewLocation)} 
            className="btn small-btn"
            disabled={existingLocations.length === 0}
          >
            {isNewLocation ? 'Existed' : 'New'}
          </button>
          {/* <button onClick={onClose} className="close-button">X</button> */}
          <div className='' onClick={onClose}>
          <IoClose className="close-button" size={20}/>
        </div>
        </div>
      </div>
      {isNewLocation ? (
        <>
          <input 
            type="text" 
            ref={searchInputRef} 
            placeholder="Select Location" 
            className="location-input" 
          />
          {mapVisible && (
            <MapContainer 
              center={position} 
              zoom={13} 
              style={{ height: '400px', width: '100%' }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker position={position} />
            </MapContainer>
          )}
        </>
      ) : (
        <>
          <div className="last-saved-container">
            <label className="last-saved-label">Last Saved</label>
            <select 
              className="location-dropdown" 
              defaultValue={existingLocations.length - 1}
              onChange={handleLocationChange}
            >
              {existingLocations.map((location, index) => (
                <option key={index} value={index} className="location-option">
                  {location}
                </option>
              ))}
            </select>
          </div>
          <div className="map-container">
            <MapContainer 
              center={position} 
              zoom={13} 
              style={{ height: '400px', width: '100%' }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker position={position} />
            </MapContainer>
          </div>
        </>
      )}

      <div className="phone-number-container">
        <span className="country-code">+251</span>
        <input 
          type="text" 
          placeholder="Phone Number" 
          className="phone-input" 
          value={localPhoneNumber.slice(3)}  
          onChange={handlePhoneNumberChange} 
          onBlur={handlePhoneInputBlur} 
          ref={phoneInputRef} 
          minLength={8}   // Set the minimum length to 8
          maxLength={8}  // Set the maximum length (e.g., 15 characters)
        />
      </div>
        </div>
        </Page>
      </KonstaApp>
  );
};

export default LocationSelector;
