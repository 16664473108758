import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { App as KonstaApp, Page, Navbar } from "konsta/react";
import Card from "./Components/Card/Card";
import "./App.css";
import cartIcon from "./assets/cart-icon.png";
import LoadingAnimation from "./Components/LoadingAnimation/LoadingAnimation";
import LocationSelector from "./Components/LocationSelector/LocationSelector";
import PaymentMethods from "./Components/PaymentMethods/PaymentMethods";
import IframeComponent from "./Components/IframeComponents/IframeComponent";
import BottomSheet from "./Components/BottomSheet/BottomSheet";
import { Link, Route, Routes } from "react-router-dom";
import History from "./Components/History/History";
import HistoryDetails from "./Components/History/HistoryDetails/HistoryDetails";
import { MdHistory } from "react-icons/md";
import { db } from "./firebase";
import { doc, setDoc } from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";

const tele = window.Telegram.WebApp;

const App = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [theme, setTheme] = useState({});
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [activePopup, setActivePopup] = useState(null);
  const [showMapAndAutocomplete, setShowMapAndAutocomplete] = useState(false);
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [token, setUserToken] = useState(null);
  const [orderId, setOrderId] = useState(null); // Add state for order ID
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const observer = useRef();

  const applyTelegramTheme = useCallback(() => {
    const themeParams = tele.themeParams;
    const cssVars = {
      "--background-color": themeParams.bg_color || "rgb(19, 19, 52)",
      "--text-color": themeParams.text_color || "#fff",
      "--button-color": themeParams.button_color || "rgb(38, 121, 38)",
      "--button-text-color": themeParams.button_text_color || "#fff",
      "--hint-color": themeParams.hint_color || "#888",
      "--button-hover-color": themeParams.button_hover_color || "#0056b3",
    };
    setTheme(cssVars);
  }, []);

  const onCartUpdate = async () => {
    await fetchCartData(userProfile.token);
    setBottomSheetVisible(false);
    tele.MainButton.show();
  };

  const fetchUserByTelegramId = useCallback(
    async (telegramId) => {
      try {
        const response = await axios.get(
          // `${process.env.REACT_APP_API_URL}/system/users/telegram/${345852551}`,
          `${process.env.REACT_APP_API_URL}/system/users/telegram/${telegramId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: process.env.REACT_APP_BEARER_TOKEN,
            },
          }
        );
        const userData = response.data.data;

        if (response.data.ok) {
          setUserProfile(userData);
          fetchCartData(userData.token);
          loadProducts(page, userData.token);
          fetchLocations(userData.token);
          applyTelegramTheme();
        }
      } catch (error) {
        if (error.response?.status === 404) {
          requestContact();
        } else {
          // console.error("Failed to fetch user:", error);
          toast.error(
            `Failed to fetch user:  ${
              error.response &&
              error.response.data &&
              error.response.data.error.message
            }`
          );
          // window.Telegram.WebApp.showAlert(`Error: ${error.response?.status} URL: ${error.config?.url}`);
        }
      }
    },
    [applyTelegramTheme, page]
  );

  const requestContact = () => {
    window.Telegram.WebApp.requestContact((sent, event) => {
      if (
        // sent &&
        // event &&
        // event.responseUnsafe &&
        // event.responseUnsafe.contact &&
        event.responseUnsafe.contact.phone_number
      ) {
        fetchUserByPhoneNumber(event.responseUnsafe.contact.phone_number);
      } else {
        // console.log(
        //   "User declined to share phone number or an error occurred."
        // );
        setTimeout(() => {
          requestContact();
        }, 1000); // Retry after 1 second
      }
    });
  };

  const fetchUserByPhoneNumber = async (phoneNumber) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/system/users/${phoneNumber.replace(
          /[^0-9]/g,
          ""
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: process.env.REACT_APP_BEARER_TOKEN,
          },
        }
      );

      // const response2 = await axios.get(
      //   `${process.env.REACT_APP_API_URL}/system/users/${251929040099}`,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: process.env.REACT_APP_BEARER_TOKEN,
      //     },
      //   }
      // );

      const userData = response.data.data;
      if (response.data.ok) {
        setUserProfile(userData);

        const updateTelegramIdPromise = axios.patch(
          `${process.env.REACT_APP_API_URL}/users`,
          {
            telegram_id: "" + window.Telegram.WebApp.initDataUnsafe.user.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          }
        );

        const fetchCartDataPromise = fetchCartData(userData.token);
        const loadProductsPromise = loadProducts(page, userData.token);
        const applyTelegramThemePromise = new Promise((resolve) => {
          applyTelegramTheme();
          resolve();
        });

        await Promise.allSettled([
          updateTelegramIdPromise,
          fetchCartDataPromise,
          loadProductsPromise,
          applyTelegramThemePromise,
        ]);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        createUser(phoneNumber);
      } else {
        // tele.showAlert("Failed to fetch user by phone number:", error.response?.status);
      }
    }
  };

  const createUser = async (phoneNumber) => {
    try {
      const user = window.Telegram.WebApp.initDataUnsafe.user;
      const newUser = {
        name: "" + user.first_name,
        last_name: "" + user.last_name ? user.last_name : user.first_name,
        phone: phoneNumber.replace(/[^0-9]/g, ""),
        country: "ETH",
        telegram_id: "" + user.id,
        password: "123456",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users`,
        newUser,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.ok) {
        fetchUserByPhoneNumber(response.data.data.user_id);
        try {
          await setDoc(doc(db, "B2b_users", response.data.data.user_id), {
            userId: response.data.data.user_id,
            createdAt: new Date().toISOString(),
          });
          // console.log("User ID stored in Firebase");
        } catch (firebaseError) {
          // console.error("Error storing user ID in Firebase:", firebaseError);
        }
      }
    } catch (error) {
      let errorMessage = "Error creating user.";
      if (error.response) {
        errorMessage += " Details: " + JSON.stringify(error.response.data);
      } else if (error.request) {
        errorMessage += " No response was received.";
      } else {
        errorMessage += " An error occurred setting up the request.";
      }

      toast.error(`Error  :  ${errorMessage}`);

      // window.Telegram.WebApp.showAlert(errorMessage);
      // tele.showAlert(errorMessage);
    }
  };

  const fetchCartData = async (token) => {
    const url = `${process.env.REACT_APP_API_URL}/carts/personal`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (result.ok) {
        setCartItems(
          result.data.map((item) => ({
            id: item.product_id,
            quantity: item.quantity,
            item_total_price: parseFloat(item.item_total_price),
            price: item.single_deal.original_price,
            name: item.name,
            image:
              "https://productionchipchip.fra1.digitaloceanspaces.com/" +
              item.primary_image,
          }))
        );
      } else {
        console.error("Failed to fetch cart data:", result);
      }
    } catch (error) {
      // window.Telegram.WebApp.showAlert(error);
      toast.error(
        `Error :  ${
          error.response &&
          error.response.data &&
          error.response.data.error.message
        }`
      );
      // console.error("Error fetching cart data:", error);
    }
  };

  const loadProducts = async (page, token) => {
    setIsLoading(true);
    setUserToken(token);
    const url = `${process.env.REACT_APP_API_URL}/products?page=${page}&per_page=10&filter=[{"columnField":"bulk_exist","operatorValue":"=","value":"true"}]`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();

      if (result.ok) {
        const newProducts = result.data.map((product) => ({
          ...product,
          quantity: 0,
          item_total_price: 0,
        }));

        setProducts((prevProducts) => {
          // Filter out duplicates
          const productIds = new Set(prevProducts.map((p) => p.id));
          const filteredProducts = newProducts.filter(
            (p) => !productIds.has(p.id)
          );
          return [...prevProducts, ...filteredProducts];
        });
      } else {
        // Handle error
      }
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLocations = async (token) => {
    const url = `${process.env.REACT_APP_API_URL}/locations/last_mile`;

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.ok) {
        const sortedLocations = response.data.data.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        setLocations(sortedLocations);
        if (sortedLocations.map((location) => location.name).length === 0) {
          setShowMapAndAutocomplete(true);
        }
      } else {
        console.error("Failed to fetch locations:", response.data);
      }
    } catch (error) {
      // console.error("Error fetching locations:", error);
    }
  };

  const onAddBulk = (product) => {
    setSelectedProduct(product);
    setBottomSheetVisible(true);
    tele.MainButton.hide();
  };

  const onAdd = async (product) => {
    const url = `${process.env.REACT_APP_API_URL}/carts`;
    const token = userProfile.token;

    // Check for the smallest bulk deal quantity
    const minBulkDealQuantity = product.bulk_deals
      ? Math.min(...product.bulk_deals.map((deal) => deal.quantity))
      : 1;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          product_id: product.id,
          quantity: product.quantity === 0 ? minBulkDealQuantity : 1, // Use minBulkDealQuantity if quantity is 0
        }),
      });

      const result = await response.json();
      if (result.ok) {
        fetchCartData(token);
      } else {
        console.error("Failed to add to cart:", result);
        toast.error(`Error :  Failed to add to cart`);
      }
    } catch (error) {
      // console.error("Error:", error);
      toast.error(
        `Error  :  ${
          error.response &&
          error.response.data &&
          error.response.data.error.message
        }`
      );
    }
  };

  const onRemove = async (product) => {
    const url = `${process.env.REACT_APP_API_URL}/carts/${product.id}`;
    const token = userProfile.token;

    // Check for the smallest bulk deal quantity
    const minBulkDealQuantity = product.bulk_deals
      ? Math.min(...product.bulk_deals.map((deal) => deal.quantity))
      : 1;

    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          product_id: product.id,
          quantity:
            product.quantity <= minBulkDealQuantity ? -product.quantity : -1, // Pass 0 if quantity reaches minBulkDealQuantity
        }),
      });

      const result = await response.json();
      if (result.ok) {
        fetchCartData(token);
      } else {
        // console.error("Failed to remove from cart:", result);
        toast.error(`Failed to remove from cart : `);
      }
    } catch (error) {
      // console.error("Error:", error);
      toast.error(
        `Error  :  ${
          error.response &&
          error.response.data &&
          error.response.data.error.message
        }`
      );
    }
  };

  const getTotalCartQuantity = () => {
    return cartItems.reduce((acc, item) => acc + item.quantity, 0);
  };

  const getTotalCartPrice = () => {
    return cartItems.reduce((acc, item) => acc + item.item_total_price, 0);
  };
  const getTotalToPay = async () => {
    const url = `${process.env.REACT_APP_API_URL}/system/config`;
    // tele.showAlert(`${process.env.REACT_APP_API_URL}/system/config`);
    // tele.showAlert(url);

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${process.env.REACT_APP_BEARER_TOKEN}`,
        },
      });
      // tele.showAlert(response.data.data.last_mile_delivery_price);
      if (response.data.ok) {
        return response.data.data.last_mile_delivery_price;
      } else {
        console.error("Failed to fetch delivery charge:", response.data);
        // tele.showAlert('Failed to fetch delivery charge.');
        return null;
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Unknown error occurred";
      // tele.showAlert(`Error fetching delivery charge: ${errorMessage}`);
      toast.error(`Error  :  ${errorMessage}`);
      return null;
    }
  };

  const lastProductElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const handleCheckout = () => {
    setActivePopup("location");
  };

  const handleGetPaymentList = () => {
    setActivePopup("paymentMethods");
  };

  const handleCloseLocationSelector = () => {
    // setActivePopup(null);
    window.location.reload(); // Reloads the current page
  };

  const handleClosePaymentMethods = () => {
    // setActivePopup('location');
    window.location.reload(); // Reloads the current page
  };

  const toggleMapAndAutocomplete = () => {
    setShowMapAndAutocomplete(!showMapAndAutocomplete); // Toggle between map and dropdown
  };

  const handlePay = async (selectedValue) => {
    // tele.showAlert(selectedPaymentMethod.label);
    tele.MainButton.disable();
    tele.MainButton.setParams({
      text: "Processing...", // Change the button text
      color: "#036698", // Change the background color to a disabled-looking color
      text_color: "#ffffff", // Adjust text color if needed
    });
    if (!selectedValue.label) {
      tele.showAlert("No payment method selected.");
      return;
    }
    const supportedMethods = selectedPaymentMethod.supported_methods;
    // tele.showAlert(
    //   `2 payments ${selectedValue.supported_methods[0]} & ${selectedValue.supported_methods[1]}`
    // );
    let paymentMethodFunction;
    if (
      supportedMethods.includes("ussd") ||
      supportedMethods.includes("bill")
    ) {
      paymentMethodFunction = initiatePayment;
    } else {
      paymentMethodFunction = initiatePaymentWeb;
    }

    if (selectedLocation.new) {
      try {
        const newLocation = {
          additional_contact_name: userProfile.name,
          additional_contact_phone:
            selectedLocation.phoneNumber !== undefined
              ? selectedLocation.phoneNumber
              : userProfile.phone,
          delivery_type: "DROP_OF_POINT",
          location: {
            latitude: selectedLocation.position[0],
            longitude: selectedLocation.position[1],
          },
          location_title: selectedLocation.title,
          name: selectedLocation.title,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/locations`,
          newLocation,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userProfile.token}`,
            },
          }
        );

        if (response.data.ok) {
          const locationId = response.data.data.id;
          paymentMethodFunction(
            locationId,
            selectedValue.id,
            selectedPaymentMethod
          );
        } else {
          // tele.showAlert("Failed to save location:", response.data);
        }
        tele.MainButton.show();
      } catch (error) {
        tele.MainButton.show();
        // tele.showAlert("Error saving location:", error);
        toast.error(
          `Error saving location: :  ${
            error.response &&
            error.response.data &&
            error.response.data.error.message
          }`
        );
      }
    } else {
      if (
        selectedLocation.phoneNumber === userProfile.phone ||
        selectedLocation.phoneNumber === undefined
      ) {
        paymentMethodFunction(
          selectedLocation.id,
          selectedValue.id,
          selectedPaymentMethod
        );
        // tele.showAlert("everything stay same");
      } else {
        const location = locations.find(
          (location) => location.id === selectedLocation.id
        );
        // tele.showAlert("existed location phone number changed");

        try {
          const newLocation = {
            additional_contact_name: userProfile.name,
            additional_contact_phone: selectedLocation.phoneNumber,
            delivery_type: "DROP_OF_POINT",
            location: {
              latitude: location.location.latitude,
              longitude: location.location.longitude,
            },
            location_title: location.location_title,
            name: location.location_title,
          };

          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/locations`,
            newLocation,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userProfile.token}`,
              },
            }
          );

          if (response.data.ok) {
            const locationId = response.data.data.id;
            paymentMethodFunction(
              locationId,
              selectedValue.id,
              selectedPaymentMethod
            );
          } else {
            tele.showAlert("Failed to save location:", response.data);
          }
          tele.MainButton.show();
        } catch (error) {
          tele.MainButton.show();
          tele.showAlert("Error saving location:", error);
        }
      }
    }
  };

  const initiatePaymentWeb = async (
    locationId,
    paymentMethodId,
    selectedPaymentMethod
  ) => {
    try {
      const paymentData = {
        location_id: locationId,
        payment_method_id: paymentMethodId,
        platform: "web",
        // device: "mobile",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_V2}/orders/personal`,
        paymentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userProfile.token}`,
          },
        }
      );

      if (response.data.ok) {
        setPaymentUrl(response.data.data.payment_url);
        setActivePopup("paymentUrl");
        setOrderId(response.data.data.order_id); // Set the order ID state
      } else {
        // tele.showAlert("Failed to initiate payment:", response.data);
        toast.error(`Failed to initiate payment`);
      }
    } catch (error) {
      // tele.showAlert("Error initiating payment:", error);
      toast.error(
        `Error initiating payment :  ${
          error.response &&
          error.response.data &&
          error.response.data.error.message
        }`
      );
    }
  };
  const initiatePayment = async (
    locationId,
    paymentMethodId,
    selectedPaymentMethod
  ) => {
    try {
      const paymentData = {
        location_id: locationId,
        payment_method_id: paymentMethodId,
        platform: selectedPaymentMethod.name === "MPesa" ? "ussd" : "web",
        account_number: "2517" + selectedPaymentMethod.accountNumber,
      };

      console.log("Payment Data:", paymentData); // Log the payment data to debug

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_V2}/orders/personal`,
        paymentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userProfile.token}`,
          },
        }
      );

      if (response.data.ok) {
        setOrderId(response.data.data.order_id); // Set the order ID state
      } else {
        // console.log("Payment initiation failed response:", response.data); // Log the response data if it's not OK
        // tele.showAlert("Failed to initiate payment:", response.data);
        toast.error(`Failed to initiate payment`);
      }
    } catch (error) {
      // console.error("Error initiating payment:", error); // Log the full error object
      toast.error(
        `Error initiating payment :  ${
          error.response &&
          error.response.data &&
          error.response.data.error.message
        }`
      );
    }
  };

  const handleCloseIframe = () => {
    window.location.reload(); // Reloads the current page
  };

  useEffect(() => {
    if (window.Telegram.WebApp.initDataUnsafe) {
      const telegramId = window.Telegram.WebApp.initDataUnsafe.user.id;
      // fetchUserByTelegramId(839532967);
      fetchUserByTelegramId(telegramId);
    } else {
      // window.Telegram.WebApp.showAlert("Telegram data not initialized.");
    }
  }, []);

  useEffect(() => {
    if (userProfile) {
      loadProducts(page, userProfile.token);
    }
  }, [page, userProfile]);

  useEffect(() => {
    if (getTotalCartQuantity() > 0 && !bottomSheetVisible) {
      const totalCartPrice = getTotalCartPrice().toFixed(2); // Ensure two decimal places
      tele.MainButton.setText(`Place Order ${totalCartPrice} ETB`);
      if (totalCartPrice > 0) {
        tele.MainButton.show();
      }
    } else {
      tele.MainButton.hide();
    }
  }, [cartItems, bottomSheetVisible]);

  useEffect(() => {
    const updateButtonText = async () => {
      if (activePopup === "location") {
        tele.MainButton.setText("Confirm Delivery Location");
        tele.MainButton.onClick(handleGetPaymentList);
        tele.MainButton.show();
      } else if (activePopup === "paymentMethods") {
        const deliveryCharge = await getTotalToPay();
        if (deliveryCharge !== null) {
          const totalCartPrice = getTotalCartPrice(); // Assume this is defined elsewhere
          const totalPay = Number(totalCartPrice) + Number(deliveryCharge);
          tele.MainButton.setText(`Total to Pay ${totalPay.toFixed(2)} ETB`);
          tele.MainButton.show();
        }
      } else if (activePopup === "paymentUrl") {
        tele.MainButton.hide();
      } else if (!bottomSheetVisible) {
        const itemPrice = getTotalCartPrice();
        tele.MainButton.setText(`Place Order ${itemPrice.toFixed(2)} ETB`);
        tele.MainButton.onClick(handleCheckout);
        if (itemPrice > 0) {
          tele.MainButton.show();
        }
      }
    };
    updateButtonText();
  }, [activePopup, cartItems, selectedPaymentMethod, bottomSheetVisible]);

  useEffect(() => {
    if (activePopup === "paymentUrl" || orderId) {
      const intervalId = setInterval(async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/order/personal/${orderId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userProfile.token}`,
              },
            }
          );

          if (response.data.ok) {
            const orderStatus = response.data.data.status;

            // Store orderId in Firebase
            try {
              await setDoc(doc(db, "B2b_orders", orderId), {
                orderId: orderId,
                // groupId: groupData.id,
                timestamp: new Date().toISOString(),
              });
              // console.log("Order ID stored in Firebase");
            } catch (error) {
              // console.error("Error storing order ID in Firebase:", error);
            }

            if (orderStatus === "COMPLETED") {
              clearInterval(intervalId);

              const telegramUserId = tele.initDataUnsafe.user.id;
              // const telegramUserId = tele.initDataUnsafe.user.id;
              const message = "Your payment has been completed successfully!";
              const url = `https://api.telegram.org/bot7138091898:AAH8iTIZ8r_aj2YJK3I1-UnVd0hmSlMbeJY/sendMessage`;

              const data = {
                chat_id: Number(telegramUserId),
                text: message,
              };

              fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data.ok) {
                    // console.log('Message sent successfully');
                    // window.Telegram.WebApp.showAlert('Message sent successfully');
                  } else {
                    // console.log('Failed to send message', data);
                    // window.Telegram.WebApp.showAlert('Failed to send message');
                  }
                  window.location.href = `/history?user=${
                    userProfile.name
                  }&userImage=${
                    userProfile.profile_image
                  }&cartQty=${0}&userToken=${userProfile.token}`;
                })
                .catch((error) => {
                  // console.error("Error sending message:", error);
                  if (error.response) {
                    console.log("Error response:", error.response);
                  }
                  // window.Telegram.WebApp.showAlert("Error sending message");
                });

              // tele.showAlert("Payment completed");
            } else if (orderStatus !== "PENDING") {
              clearInterval(intervalId);
              tele.showAlert("Payment canceled");
              window.location.reload();
            }
          }
        } catch (error) {
          console.error("Error checking payment status:", error);
        }
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [activePopup, orderId, userProfile]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div>
            {" "}
            {userProfile ? (
              <KonstaApp theme="auto">
                {" "}
                <Page style={theme}>
                  {" "}
                  <Navbar
                    title={
                      <div className="search-container">
                        {" "}
                        <div className="profile-container">
                          {" "}
                          <img
                            src={`https://productionchipchip.fra1.digitaloceanspaces.com/${userProfile.profile_image}`}
                            alt="Profile"
                            className="profile-picture"
                          />{" "}
                          <div className="profile-welcome">
                            {" "}
                            <span className="greeting">Welcome 👋</span>{" "}
                            <span className="name"> {userProfile.name}</span>{" "}
                          </div>{" "}
                        </div>{" "}
                        {/* Link to history */}
                        <div className="icons-box">
                          {" "}
                          <button className="cart-button">
                            {" "}
                            <img
                              src={cartIcon}
                              alt="Cart"
                              className="cart-icon"
                            />{" "}
                            <span className="cart-counter-badge">
                              {" "}
                              {getTotalCartQuantity()}
                            </span>{" "}
                          </button>{" "}
                          <Link
                            to={`/history?user=${userProfile.name}&userImage=${
                              userProfile.profile_image
                            }&cartQty=${getTotalCartQuantity()}&userToken=${
                              userProfile.token
                            }

            `}
                          >
                            {" "}
                            <div className="history-icon">
                              {" "}
                              <MdHistory color="#fff" size={20} />{" "}
                            </div>{" "}
                          </Link>{" "}
                        </div>{" "}
                      </div>
                    }
                  />{" "}
                  <div className="cards__container">
                    {" "}
                    {products.length === 0 ? (
                      <p></p>
                    ) : (
                      products.map((product, index) => {
                        const cartItem = cartItems.find(
                          (item) => item.id === product.id
                        );
                        const quantity = cartItem ? cartItem.quantity : 0;
                        product.quantity = quantity;
                        const item_total_price = cartItem
                          ? cartItem.item_total_price
                          : 0;
                        let price = 0;

                        if (
                          product.bulk_deals &&
                          product.bulk_deals.length > 1
                        ) {
                          const prices = product.bulk_deals.map((deal) =>
                            parseInt(deal.bulk_price)
                          );
                          const minPrice = Math.min(...prices);
                          const maxPrice = Math.max(...prices);

                          price = `${minPrice} - ${maxPrice} ETB`;
                        } else if (
                          product.bulk_deals &&
                          product.bulk_deals.length === 1
                        ) {
                          price = `${product.bulk_deals[0].bulk_price} ETB`;
                        }

                        const addFunction =
                          product.bulk_deals && product.bulk_deals.length === 1
                            ? onAdd
                            : onAddBulk;

                        if (products.length === index + 1) {
                          return (
                            <div ref={lastProductElementRef} key={product.id}>
                              {" "}
                              <Card
                                food={{
                                  title: product.name,
                                  price: price,
                                  Image:
                                    "https://productionchipchip.fra1.digitaloceanspaces.com/" +
                                    product.primary_image,
                                  id: product.id,
                                  quantity: quantity,
                                  item_total_price: item_total_price,
                                  measuring_unit: product.measuring_unit,
                                  bulk_deals: product.bulk_deals,
                                }}
                                onRemove={onRemove}
                                onAdd={addFunction}
                              />{" "}
                            </div>
                          );
                        } else {
                          return (
                            <Card
                              food={{
                                title: product.name,
                                price: price,
                                Image:
                                  "https://productionchipchip.fra1.digitaloceanspaces.com/" +
                                  product.primary_image,
                                id: product.id,
                                quantity: quantity,
                                item_total_price: item_total_price,
                                measuring_unit: product.measuring_unit,
                                bulk_deals: product.bulk_deals,
                              }}
                              key={product.id}
                              onAdd={addFunction}
                              onRemove={onRemove}
                            />
                          );
                        }
                      })
                    )}
                    {isLoading && (
                      <div className="loading-animation-container">
                        {" "}
                        <LoadingAnimation />{" "}
                      </div>
                    )}
                  </div>{" "}
                </Page>{" "}
              </KonstaApp>
            ) : (
              <p>Loading profile...</p>
            )}
            {activePopup === "location" && (
              <LocationSelector
                onClose={handleCloseLocationSelector}
                setLocation={setSelectedLocation}
                showMapAndAutocomplete={showMapAndAutocomplete}
                toggleMapAndAutocomplete={toggleMapAndAutocomplete}
                existingLocations={locations.map((location) => location.name)}
                phoneNumber={userProfile.phone}
                locations={locations}
              />
            )}
            {activePopup === "paymentMethods" && (
              <KonstaApp heme="auto">
                <Page style={theme}>
                  <PaymentMethods
                    onClose={handleClosePaymentMethods}
                    token={userProfile.token}
                    setSelectedMethod={setSelectedPaymentMethod}
                    handlePay={handlePay}
                    activePopup={activePopup}
                  />
                </Page>
              </KonstaApp>
            )}
            <IframeComponent
              paymentUrl={paymentUrl}
              closeIframe={handleCloseIframe}
            />
            <KonstaApp heme="auto">
              <Page style={theme}>
                <BottomSheet
                  visible={bottomSheetVisible}
                  onClose={() => setBottomSheetVisible(false)}
                  product={selectedProduct}
                  token={token}
                  onCartUpdate={onCartUpdate}
                />
              </Page>
            </KonstaApp>
          </div>
        }
      />
      <Route path="/history" element={<History />} />
      <Route path="/history/details" element={<HistoryDetails />} />
    </Routes>
  );
};

export default App;
