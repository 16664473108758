import React, { useCallback, useEffect, useState } from 'react';
import { App as KonstaApp, Page, Navbar } from 'konsta/react';
import LoadingAnimation from '../../LoadingAnimation/LoadingAnimation';
import { Link } from 'react-router-dom';
import cartIcon from "../../../assets/cart-icon.png";
import { useLocation } from 'react-router-dom';
import { MdHistory } from 'react-icons/md';
import { IoArrowBackCircle } from "react-icons/io5";
import axios from 'axios';
import "./HistoryDetails.css";
import ItemCard from '../ItemCard/ItemCard';

const tele = window.Telegram.WebApp;

const HistoryDetails = () => {
    const [theme, setTheme] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [historyOrders, setHistoryHorders] = useState(null);
    const [historyItems, setHistoryItems] = useState(null);
     const location = useLocation();

  // Parse the URL search parameters
  const params = new URLSearchParams(location.search);
  const user = params.get('user');
  const userImage = params.get('userImage');
  const cartQty = params.get('cartQty');
  const userToken = params.get('userToken');
  const itemId = params.get('itemId');
  const currentPage = params.get('currentPage');
      
    const applyTelegramTheme = useCallback(() => {
    const themeParams = tele.themeParams;
// window.Telegram.WebApp.MainButton.hide()
        
    const cssVars = {
      '--background-color': themeParams.bg_color || 'rgb(19, 19, 52)',
      '--text-color': themeParams.text_color || '#fff',
      '--button-color': themeParams.button_color || 'rgb(38, 121, 38)',
      '--button-text-color': themeParams.button_text_color || '#fff',
      '--hint-color': themeParams.hint_color || '#888',
      '--button-hover-color': themeParams.button_hover_color || '#0056b3'

    };
    setTheme(cssVars);
    }, []);


    const loadHistoryOrders = async (page) => {
        
    setIsLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/order/personal?page=${currentPage}&per_page=10&filter=[{"columnField":"o.status", "operatorValue":"=", "value":"COMPLETED"}]&sort={"field":"o.updated_at","order":"desc"}`;
        
        // const url = `${process.env.REACT_APP_API_URL}/order/personal`;

    try {
      const response = await axios.get(
          url,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: process.env.REACT_APP_BEARER_TOKEN,
            //   Authorization: userToken,
            },
          }
        );
        console.log("response", response);
         
        applyTelegramTheme()
        setHistoryHorders(response.data.data);
        // window.Telegram.WebApp.MainButton.hide();

     setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    } 
  };

    
    useEffect(() => {
        window.Telegram.WebApp.MainButton.hide();
    },[applyTelegramTheme, historyOrders])
    

      useEffect(() => {
    
          
          loadHistoryOrders(20);
    
  }, [applyTelegramTheme]);

  return (
    <KonstaApp theme="auto">
                <Page style={theme}>
                  <Navbar
                    title={
                      <div className="search-container">
                        <div className="profile-container">
                          <img
                            src={`https://productionchipchip.fra1.digitaloceanspaces.com/${userImage}`}
                            alt="Profile"
                            className="profile-picture"
                          />
                          <div className="profile-welcome">
                            <span className="greeting">Welcome 👋</span>
                            <span className="name">{user}</span>
                          </div>
                        </div>
                        {/* Link to history */}
                        <div className="icons-box">
                          <button className="cart-button">
                            <img
                              src={cartIcon}
                              alt="Cart"
                              className="cart-icon"
                            />
                            <span className="cart-counter-badge">
                              {cartQty}
                            </span>
                          </button>
                          <Link to={`/history?user=${user}&userImage=${
                              userImage
                            }&cartQty=${cartQty}&userToken=${
                              userToken
                            }`}>
                            <div className="history-icon">
                              <IoArrowBackCircle  color="#fff" size={20} />
                            </div>
                          </Link>{" "}
                        </div>
                      </div>
                    }
                    />
                   
              <div className="history_cards__container">
                   <h4 className='title'>Orders Details</h4>
                    {isLoading && (
                      <div className="loading-animation-container">
                        <LoadingAnimation />
                      </div>
                  )}
                  
                  {
                      historyOrders && historyOrders.filter((elt)=> elt.id === itemId)[0].items.map((item, idx)=>{

                        return <ItemCard item={item}  key={idx}/>
                      })
                  }
                  </div>
                </Page>
              </KonstaApp>
  )
}

export default HistoryDetails