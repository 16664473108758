import React, { useState } from 'react';
import "./HistoryCard.css";
import ItemCard from '../ItemCard/ItemCard';
import { Link, useLocation } from 'react-router-dom';

const HistoryCard = ({ order,currentPage }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
  const {
    delivery_address,
    discount,
    items,
    status,
    subtotal,
      total_amount,
      id,
    created_at
    } = order;

    const location = useLocation();

  // Parse the URL search parameters
  const params = new URLSearchParams(location.search);
  const user = params.get('user');
  const userImage = params.get('userImage');
  const cartQty = params.get('cartQty');
  const userToken = params.get('userToken');

  return (
    <div className="history_card">
      <div className="card-left">
        <span><b>Location Name:</b> {delivery_address.delivery_location.name}</span>
        {/* <span><b>Discount :</b> {discount} ETB</span> */}
        <span><b>Items :</b> {items.length} Products</span>
        <span><b>Payment :</b> {status}</span>
        <span><b>Item Total :</b> {total_amount} ETB</span>
        <span><b>Total :</b> {subtotal}ETB</span>
        <span><b>Date :</b> {formatDate(created_at)}</span>
        </div>
      <div className="card-right">
              <Link to={`/history/details?user=${user}&userImage=${
                              userImage
                            }&cartQty=${cartQty}&userToken=${
                              userToken
                            }&itemId=${id}&currentPage=${currentPage}`}>
                <button className="view-button">View</button>
              </Link>
          </div>
          
         
    </div>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short', 
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }).format(date);
};


export default HistoryCard;
