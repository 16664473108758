import React, { useCallback, useEffect, useState } from 'react';
import { App as KonstaApp, Page, Navbar } from 'konsta/react';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import { Link, useNavigate } from 'react-router-dom';
import cartIcon from "../../assets/cart-icon.png";
import { useLocation } from 'react-router-dom';
import { MdHistory, MdHome } from 'react-icons/md';
import { IoArrowBackCircle } from "react-icons/io5";
import axios from 'axios';
import HistoryCard from './HistoryCard/HistoryCard';
import "./History.css";

const tele = window.Telegram.WebApp;

const History = () => {
    const [theme, setTheme] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [historyOrders, setHistoryHorders] = useState(null);
    const [historyItems, setHistoryItems] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const user = params.get('user');
    const userImage = params.get('userImage');
    const cartQty = params.get('cartQty');
    const userToken = params.get('userToken');

    const navigate = useNavigate();

  const backToHome = () => {
      window.location.href = "/";
  };
      
    const applyTelegramTheme = useCallback(() => {
        const themeParams = tele.themeParams;
        const cssVars = {
            '--background-color': themeParams.bg_color || 'rgb(19, 19, 52)',
            '--text-color': themeParams.text_color || '#fff',
            '--button-color': themeParams.button_color || 'rgb(38, 121, 38)',
            '--button-text-color': themeParams.button_text_color || '#fff',
            '--hint-color': themeParams.hint_color || '#888',
            '--button-hover-color': themeParams.button_hover_color || '#0056b3'
        };
        setTheme(cssVars);
    }, []);

    const loadHistoryOrders = async (page) => {
        window.scrollTo({
        top: 0,
        // behavior: 'smooth' // Smooth scrolling
    });
        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/order/personal?page=${page}&per_page=10&filter=[{"columnField":"o.status", "operatorValue":"=", "value":"COMPLETED"}]&sort={"field":"o.updated_at","order":"desc"}`;

        try {
            const response = await axios.get(
                url,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: process.env.REACT_APP_BEARER_TOKEN,
                    },
                }
            );
            console.log("response", response);
            
            applyTelegramTheme();
            setHistoryHorders(response.data.data);
            setTotalPages(Math.ceil(response.data.meta_data.total / 10)); // Assuming `total` is in response data
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        window.Telegram.WebApp.MainButton.hide();
    }, [applyTelegramTheme, historyOrders]);

    useEffect(() => {
        loadHistoryOrders(currentPage);
    }, [applyTelegramTheme]);

    const handleNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
        loadHistoryOrders(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          loadHistoryOrders(currentPage - 1);
        }
    };

    return (
        <KonstaApp theme="auto">
            <Page style={theme}>
                <Navbar
                    title={
                        <div className="search-container">
                            <div className="profile-container">
                                <img
                                    src={`https://productionchipchip.fra1.digitaloceanspaces.com/${userImage}`}
                                    alt="Profile"
                                    className="profile-picture"
                                />
                                <div className="profile-welcome">
                                    <span className="greeting">Welcome 👋</span>
                                    <span className="name">{user}</span>
                                </div>
                            </div>
                            <div className="icons-box">
                                <button className="cart-button">
                                    <img
                                        src={cartIcon}
                                        alt="Cart"
                                        className="cart-icon"
                                    />
                                    <span className="cart-counter-badge">
                                        {cartQty}
                                    </span>
                                </button>
                                {/* <Link to="/"> */}
                                    <div className="history-icon" onClick={backToHome}>
                                        <MdHome  color="#fff" size={20} />
                                    </div>
                                {/* </Link> */}
                            </div>
                        </div>
                    }
                />
                <div className="history_cards__container">
                    <h4 className='title'>History Details</h4>
                    {isLoading && (
                        <div className="loading-animation-container">
                            <LoadingAnimation />
                        </div>
                    )}
                  
                    {historyOrders && historyOrders.map((order, idx) => (
                        <HistoryCard order={order} key={idx} currentPage={currentPage} />
                    ))}
                  
                    <div className="pagination-controls">
                        <button 
                            onClick={handlePreviousPage} 
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button 
                            onClick={handleNextPage} 
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </Page>
        </KonstaApp>
    );
}

export default History;
