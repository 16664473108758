import React from 'react';
import "./ItemCard.css";

const ItemCard = ({ item }) => {
  const { primary_image, name, measuring_unit, original_price, quantity } = item;

  return (
    <div className="item-card">
      <div className="item-image">
        <img src={`https://productionchipchip.fra1.digitaloceanspaces.com/${primary_image}`} alt={name} />
      </div>
      <div className="item-details">
        <span><b>Name:</b> {name}</span>
        <span><b>Price:</b> {original_price} ETB</span>
        <span><b>Order:</b> ({measuring_unit}) * {quantity}</span>
        <span><b>Total:</b> ({Number(quantity) * Number(original_price)}) ETB</span>
        </div>
    </div>
  );
};

export default ItemCard;
