// // IframeComponent.jsx
// import React from 'react';
// import './IframeComponent.css'; // Assuming you have some CSS for the iframe popup

// const IframeComponent = ({ paymentUrl, closeIframe }) => {
//   if (!paymentUrl) return null; // Don't render the iframe if there's no URL

//   return (
//     <div className="iframe-popup">
//       <button className="close-iframe-button" onClick={closeIframe}>X</button>
//       <iframe src={paymentUrl} title="Payment" style={{ width: '80%', height: '80%' }} />
//     </div>
//   );
// };

// export default IframeComponent;
import React, { useState } from 'react';
import './IframeComponent.css'; // Ensure proper styling is applied

const IframeComponent = ({ paymentUrl, closeIframe }) => {
  const [iframeError, setIframeError] = useState(false);

  const handleIframeError = () => {
    setIframeError(true);
  };

  if (!paymentUrl) return null;

  return (
    <div className="iframe-popup">
      <button className="close-iframe-button" onClick={closeIframe}>X</button>
      {iframeError ? (
        <div className="iframe-error">Failed to load the page.</div>
      ) : (
        <iframe
          src={paymentUrl}
          title="Payment"
          style={{ width: '80%', height: '80%' }}
          onError={handleIframeError}
        />
      )}
    </div>
  );
};

export default IframeComponent;
