import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAynBJfaFqCPhOSNEeMLsz9DWKUDjCaIZ4",
  authDomain: "chipchip-social.firebaseapp.com",
  databaseURL: "https://chipchip-social-default-rtdb.firebaseio.com",
  projectId: "chipchip-social",
  storageBucket: "chipchip-social.appspot.com",
  messagingSenderId: "1094335773648",
  appId: "1:1094335773648:web:8c644d34e7c28e4c45d7d5",
  measurementId: "G-C1R5HC0V41",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBw5oeUZeOKy1DRE74juJS40PvEjL0axq0",
//   authDomain: "chipb2ctg.firebaseapp.com",
//   projectId: "chipb2ctg",
//   storageBucket: "chipb2ctg.appspot.com",
//   messagingSenderId: "882874765223",
//   appId: "1:882874765223:web:e87a67be7493ca5fe83082",
//   measurementId: "G-91TXFJLZQ7",
//   dynamicLinkDomain: "yourapp.page.link",
// };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAynBJfaFqCPhOSNEeMLsz9DWKUDjCaIZ4",
//   authDomain: "chipchip-social.firebaseapp.com",
//   databaseURL: "https://chipchip-social-default-rtdb.firebaseio.com",
//   projectId: "chipchip-social",
//   storageBucket: "chipchip-social.appspot.com",
//   messagingSenderId: "1094335773648",
//   appId: "1:1094335773648:web:8c644d34e7c28e4c45d7d5",
//   measurementId: "G-C1R5HC0V41"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
//ok
